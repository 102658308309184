@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

p {
  line-height: 1.7;
}

h1,
h2,
h3 {
  font-weight: 600;
  margin-bottom: 8px;
  color: #000;
}

.container {
  width: 100%;
  background-color: #ffffff;
  max-width: 100%;
  text-align: center;
}

.heading {
  display: flex;
  justify-content: center;
  text-align: left;
  flex-direction: column;
  font-size: 2rem;
  margin-bottom: 35px;
}

.heading2 {
  display: flex;
  justify-content: center;
  text-align: left;
  flex-direction: column;
  font-size: 2rem;
  margin-bottom: 35px;
}

.formLogin {
  display: block;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.formRegister {
  display: block;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.recover-password {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  color: rgb(64, 63, 63);
  margin-bottom: 2.5rem;
  margin-left: 0.5rem;
}

.recover-password-responsive {
  display: none;
  margin-top: 4rem;
  color: #ea4335;
  font-weight: bold;
  margin-bottom: 2.5rem;
}

.goals {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.goal {
  background-color: #f4f4f4;
  margin: 10px 0;
  padding: 20px 0 10px;
  position: relative;
}

.goal .close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  border: none;
  background: none;
}

.content {
  width: 70%;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 15px;
  border: 2px solid #f07000;
  border-radius: 11.5px;
  margin-bottom: 2px;
  font-family: inherit;
  background-color: #fff6f5;
}

.form-group2 {
  margin-bottom: 10px;
}

.form-group2 input,
.form-group2 textarea,
.form-group2 select {
  width: 100%;
  padding: 15px;
  border: 2px solid #f07000;
  border-radius: 11.5px;
  font-family: inherit;
  background-color: #fff6f5;
}

.btn {
  margin-left: auto;
  margin-right: auto;
  height: 3.3rem;
  width: 8rem;
  border: none;
  border-radius: 30px;
  background: #ff6900;
  color: #fff;
  font-size: 17px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.btn-block {
  margin-bottom: 20px;
}

.btn:hover {
  transform: scale(0.98);
}

.btn2 {
  margin-left: auto;
  margin-right: auto;
  height: 3rem;
  width: 7rem;
  border: none;
  border-radius: 30px;
  background: #ff6900;
  color: #fff;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.btn-block2 {
  margin-bottom: 2px;
}

.btn2:hover {
  transform: scale(0.98);
}

.containerPrincipal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  color: white;
}

.sectionRight {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 85%;
  height: 100%;
  background-color: #fcf6ee;
}

.sectionLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 55vw;
  height: 100vh;
}

.leftContain {
  width: 55vw;
  height: 100vh;
}

.orderLogo {
  position: absolute;
  width: 19.5%;
  height: 22%;
}

/* Icono-login */
.imgLogoLogin {
  display: flex;
  margin-left: 4.1vh;
  font-weight: none;
  margin-bottom: 3rem;
}

/* Icono-registro */
.imgLogoRegister {
  display: flex;
  margin-left: 4.1vh;
  font-weight: none;
  margin-bottom: 2rem;
}

.containerIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navBarLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
  margin-bottom: 4rem;
  margin-right: 14rem;
}

.navBarRegister {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
  margin-bottom: 4rem;
  margin-right: 14rem;
}

ul {
  list-style: none;
}

li {
  line-height: 2.2;
  display: inline;
  padding: 2rem;
}

.anclaOne {
  text-decoration: none;
  padding: 15px 38px;
  border-radius: 25px;
}

.anclaTwo {
  text-decoration: none;
  padding: 15px 30px;
  border-radius: 25px;
  border: 1px solid #f07000;
}

.txtInfoLogin {
  font-size: 1rem;
  width: 45%;
  color: #000000;
}

.txtInfoRegister {
  font-size: 1rem;
  width: 50%;
  color: #000000;
}

.register {
  display: flex;
  margin-top: 3rem;
  justify-content: center;
  color: #5f5c5c;
}

.info-register {
  justify-content: center;
  color: black;
  margin-left: 0.5rem;
  color: #ea4335;
  font-weight: bold;
  text-decoration: none;
}


/* Laptop */
@media only screen and (max-width : 1366px) {

  h1,
  h2,
  h3 {
    margin-bottom: 8px;
    font-size: 25px;
  }

  .heading {
    display: flex;
    justify-content: center;
    text-align: left;
    flex-direction: column;
    font-size: 2rem;
    margin-bottom: 9%;
  }

  .heading2 {
    display: flex;
    justify-content: center;
    text-align: left;
    flex-direction: column;
    font-size: 2rem;
    margin-bottom: 5%;
  }

  .formLogin {
    display: block;
    justify-content: center;
    align-items: center;
    width: 60%;
  }

  .formRegister {
    display: block;
    justify-content: center;
    align-items: center;
    width: 60%;
  }

  .form-group2 {
    margin-bottom: 10px;
  }

  .form-group2 input {
    width: 100%;
    padding: 10px;
    border: 2px solid #f07000;
    border-radius: 11.5px;
    font-family: inherit;
    background-color: #fff6f5;
  }

  .recover-password {
    display: flex;
    justify-content: center;
    margin-top: 1.3rem;
    color: rgb(64, 63, 63);
  }

  .btn {
    border: none;
    border-radius: 30px;
    background: #ff6900;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.2rem;
  }

  .btn2 {
    border: none;
    border-radius: 30px;
    background: #ff6900;
    color: #fff;
    font-size: 17px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.2rem;
  }

  .sectionRight {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 85%;
    height: 100%;
    background-color: #fcf6ee;
  }

  .sectionLeft {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 55vw;
    height: 100vh;
  }

  .leftContain {
    width: 55vw;
    height: 100vh;
  }

  .orderLogo {
    position: absolute;
    width: 19.5%;
    height: 22%;
  }

  .imgLogoLogin {
    display: none;
    margin-left: 4.1vh;
    font-weight: none;
    margin-bottom: 3rem;
  }

  .imgLogoRegister {
    display: none;
    margin-left: 4.1vh;
    font-weight: none;
    margin-bottom: -1rem;
  }

  .containerIcon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navBarLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    margin-bottom: 2rem;
    margin-top: 2rem;
    margin-right: 0rem;
  }

  .navBarRegister {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    margin-bottom: 2rem;
    margin-right: 0rem;
  }

  ul {
    list-style: none;
  }

  li {
    line-height: 2.2;
    display: inline;
    padding: 2rem;
  }

  .anclaOne {
    text-decoration: none;
    padding: 18px 38px;
    border-radius: 25px;
  }

  .anclaTwo {
    text-decoration: none;
    padding: 18px 30px;
    border-radius: 25px;
    border: 1px solid #f07000;
    margin-right: 4rem;
  }

  .txtInfoLogin {
    font-size: 1rem;
    width: 45%;
    color: #000000;
  }

  .txtInfoRegister {
    font-size: 0.9rem;
    width: 50%;
    color: #000000;
  }

  .register {
    display: none;
    justify-content: center;
    color: #5f5c5c;
  }

  .info-register {
    justify-content: center;
    color: black;
    margin-left: 0.5rem;
    color: #ea4335;
    font-weight: bold;
    text-decoration: none;
  }

}


/* Mobile - acomodar */
@media only screen and (max-width : 600px) {

  .imgLogoLogin {
    display: none;
    margin-left: 4.1vh;
    font-weight: none;
  }

  .imgLogoRegister {
    display: none;
    margin-left: 4.1vh;
    font-weight: none;
  }

  .formLogin {
    background-color: white;
    width: 70%;
    height: 100%;
  }

  .formRegister {
    background-color: white;
    width: 70%;
    height: 100%;
  }

  .heading h1 {
    font-size: 2rem;
    text-align: left;
    margin-top: 1.5rem;
  }

  .heading p {
    font-size: 1rem;
    width: 80%;
    text-align: left;
    color: #555;
  }

  .recover-password {
    display: flex;
    justify-content: left;
  }

  .btn {
    font-size: 20px;
  }

  .sectionLeft {
    display: none;
  }

  .imgLogoLogin {
    display: flex;
    margin-top: 2rem;
  }

  .imgLogoRegister {
    display: flex;
    margin-top: 2rem;
  }

  .btn-block {
    width: 100%;
    border-radius: 10px;
  }

  .navBarLogin {
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-right: 0rem;
  }

  .navBarRegister {
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-right: 0rem;
  }

  .sectionRight {
    background-color: white;
  }

  .recover-password {
    display: none;
  }

  .recover-password-responsive {
    display: flex;
    margin-top: 2.2rem;
    text-decoration: none;
    margin-bottom: 1rem;
    margin-left: 0.2rem;
  }

  .register {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #5f5c5c;
  }

}


/* HomePage */

.mainHome {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100vw;
  height: 100vh;
  background-color: #a39d9d;
}

.containerText {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #FFEFD8;
}

.containImage {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  height: 100%;
  align-items: center;
  background-color: #FFEFD8;
}

.imgPlate {
  width: 50%;
  height: 50%;
}

.information {
  margin: 4rem;
  width: 70%;
  flex-wrap: wrap;
  font-size: 20px;
}

.titleInfo {
  width: 70%;
  font-size: 65px;
  flex-wrap: wrap;
}

.btnExplore {
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  width: 18%;
  background-color: #EA6A12;
  padding: 1.2rem;
  border-radius: 5rem;
  font-size: 18px;
  color: white;
  font-weight: 200;
  border: none;
  cursor: pointer;
  margin-right: auto;
  margin-left: 9rem;
  font-weight: bold;
}

.btnExplore:hover {
  background-color: #bf8914;
}

.lists {
  display: block;
  margin: -2rem;
}

.anchor {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffffff;
  text-decoration: none;
  padding: 11px 25px;
  border-radius: 25px;
  color: #000000;
  font-weight: bold;
}

.anchor:hover {
  background-color: #e8a87e;
  color: #000000;
}

.imgPlateAnchor {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}


/* Laptop */
@media only screen and (max-width : 1366px) {
  .mainHome {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: #a39d9d;
  }

  .containerText {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
    width: 50%;
    height: 100%;
    background-color: #FFEFD8;
  }

  .containImage {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    height: 100%;
    align-items: center;
    background-color: #FFEFD8;
  }

  .imgPlate {
    width: 50%;
    height: 50%;
  }

  .information {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    width: 70%;
    flex-wrap: wrap;
    font-size: 18px;
  }

  .titleInfo {
    width: 70%;
    font-size: 55px;
    flex-wrap: wrap;
  }

  .btnExplore {
    cursor: pointer;
    margin-right: auto;
    margin-left: 6.2rem;
    font-weight: bold;
  }

  .lists {
    display: block;
    margin: 0.5rem;
  }

  .anchor {
    font-size: 15px;
  }
}


/* Tablet */
@media only screen and (max-width: 768px) {

  .mainHome{
    display: flex;
    flex-direction: column;
    text-align: center;
    flex-wrap: wrap;
    width: 100vw;
    height: 100%;
    background-color: #FFEFD8;
    overflow-y: scroll;
  }

  .containerText{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  
  .containImage{
    display: flex;
    justify-content: space-evenly ;
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: #FFEFD8;
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
  
  .imgPlate{
    width: 45%;
    height: 65%;
  }
  
  .information{
    margin: 1rem;
    width: 70%;
    text-align: center;
    flex-wrap: wrap;
  }
  
  .titleInfo{
    width: 50%;
    margin-top: 3rem;
    text-align: center;
    flex-wrap: wrap;
    font-size: 53px;
  }
  
  .btnExplore{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.9rem;
    width:27%;
    font-size: 14px;
    font-weight: bold;
    margin-left: auto;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
   
  .btnExplore:hover{
    background-color: #bf8914;
  }
  
  .lists{
    display: block;
    flex-wrap: wrap;
  }
  
  .anchor{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    background-color: #ffffff;
    text-decoration: none;
    padding: 8px 10px;
    border-radius: 25px;
    color: #000000;
    font-weight: bold;
  }
  
  .anchor:hover{
    background-color: #e8a87e;
    color: #000000;
  }
  
  .imgPlateAnchor{
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}



/* Mobile HomePage */
@media only screen and (max-width: 600px) {

  .mainHome{
    display: flex;
    flex-direction: column;
    text-align: center;
    flex-wrap: wrap;
    width: 100vw;
    height: 100%;
    overflow-y: scroll;
  }

  .containerText{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  
  .containImage{
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: #FFEFD8;
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
  
  .imgPlate{
    width: 60%;
    height: 20%;
    margin-bottom: 2rem;
  }
  
  .information{
    margin: 0.5rem;
    width: 85%;
    font-size: 17px;
    text-align: center;
    flex-wrap: wrap;
  }
  
  .titleInfo{
    width: 70%;
    margin-top: 3rem;
    margin-bottom: 1rem;
    text-align: center;
    flex-wrap: wrap;
    font-size: 35px;
  }
  
  .btnExplore{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.9rem;
    width:27%;
    font-size: 14px;
    font-weight: bold;
    margin-left: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
   
  .btnExplore:hover{
    background-color: #bf8914;
  }
  
  .lists{
    display: block;
    flex-wrap: wrap;
  }
  
  .anchor{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    background-color: #ffffff;
    text-decoration: none;
    padding: 18px 35px;
    border-radius: 25px;
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    margin: -1rem;
  }
  
  .anchor:hover{
    background-color: #e8a87e;
    color: #000000;
  }
  
  .imgPlateAnchor{
    width: 30px;
    height: 30px;
    margin-right: 25px;
  }
}




/* Forgot-password */

.mainForgotPassword {
  display: flex;
  background-image: url(../src/Images/imageBackground.jpg);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  filter: grayscale(50%);
}

.formResetPassword {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 25px;
  border: 2px solid #ffffff;
  width: 30%;
  height: 42%;
  backdrop-filter: blur(10px);

}

h3 {
  width: 100%;
  margin-top: 2rem;
  font-size: 1.7rem;
  margin-bottom: 5%;
  color: #ffffff;
}

.inputEmail {
  width: 80%;
  padding: 15px;
  border: 2px solid #ff7b08;
  border-radius: 10px;
  margin-bottom: 7%;
  font-size: 17px;
  background-color: #f7e2e0;
}

.btnSendEmail {
  background-color: #ff6900;
  border-radius: 10px;
  width: 80%;
  height: 15%;
  color: white;
  border: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.btnExit{
  text-decoration: none;
  position: absolute;
  bottom: 83%;
  right: 85.5%;
  padding: 4px 12px;
  border-radius: 25px;
  background-color: #f07000;
  color: white;
  border: 2px solid white;
}


/* Laptop 1 forgot-password  */
@media only screen and (max-width: 1366px) {
  .formResetPassword {
    width: 35%;
    height: 50%;
  }

  h3 {
    font-size: 1.4rem;
  }

  .btnSendEmail {
    font-size: 16px;
  }

  .btnExit{
    text-decoration: none;
    position: absolute;
    bottom: 83%;
    right: 82%;
    padding: 4px 12px;
    border-radius: 25px;
    background-color: #f07000;
    color: white;
    border: 2px solid white;
  }

}



/* Laptop 2 forgot-password  */
@media only screen and (max-width: 1024px) {
  .formResetPassword {
    width: 45%;
    height: 50%;
  }

  h3 {
    font-size: 1.4rem;
  }

  .btnSendEmail {
    font-size: 16px;
  }

  .btnExit{
    text-decoration: none;
    position: absolute;
    bottom: 83%;
    right: 82%;
    padding: 4px 12px;
    border-radius: 25px;
    background-color: #f07000;
    color: white;
    border: 2px solid white;
  }

}


/* Tablet forgot-password  */
@media only screen and (max-width: 768px) {
  .formResetPassword {
    width: 50%;
    height: 45%;
  }
  
  h3 {
    margin-top: 2rem;
    font-size: 1.3rem;
  }
  .btnSendEmail {
    font-size: 16px;
  }

  .btnExit{
    text-decoration: none;
    position: absolute;
    bottom: 83%;
    right: 80%;
    padding: 4px 12px;
    border-radius: 25px;
    background-color: #f07000;
    color: white;
    border: 2px solid white;
  }

}


/* Mobile forgot-password */
@media only screen and (max-width: 600px) {
  .formResetPassword {
    width: 80%;
    height: 45%;
  }
  
  h3 {
    font-size: 1.2rem;
  }
  .btnSendEmail {
    font-size: 16px;
  }

  .btnExit{
    text-decoration: none;
    position: absolute;
    bottom: 82%;
    right: 78%;
    padding: 4px 12px;
    border-radius: 25px;
    background-color: #f07000;
    color: white;
    border: 2px solid white;
  }

}



